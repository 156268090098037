import React, { FC, useEffect } from 'react';

import {
  Form,
  Radio,
  Checkbox,
  Segment,
  List,
  ListItem,
} from 'semantic-ui-react';

const radioOptions = [
  { key: 'ALL', value: 'ALL', text: 'All columns' },
  { key: 'SELECTED', value: 'SELECTED', text: 'Selected columns' },
];

const columnOptions = {
  full: [
    { key: 'created_at', value: 'created_at', text: 'created_at' },
    { key: 'updated_at', value: 'updated_at', text: 'updated_at' },
    { key: 'da_first_name', value: 'da_first_name', text: 'da_first_name' },
    { key: 'da_middle_name', value: 'da_middle_name', text: 'da_middle_name' },
    { key: 'da_last_name', value: 'da_last_name', text: 'da_last_name' },
    { key: 'first_name', value: 'first_name', text: 'first_name' },
    { key: 'middle_name', value: 'middle_name', text: 'middle_name' },
    { key: 'last_name', value: 'last_name', text: 'last_name' },
    { key: 'country', value: 'country', text: 'country' },
    { key: 'state', value: 'state', text: 'state' },
    { key: 'party', value: 'party', text: 'party' },
    { key: 'join_da', value: 'join_da', text: 'join_da' },
    {
      key: 'nb_add_or_update',
      value: 'nb_add_or_update',
      text: 'nb_add_or_update',
    },
    { key: 'nb_id', value: 'nb_id', text: 'nb_id' },
    { key: 'email', value: 'email', text: 'email' },
    {
      key: 'da_email_entered',
      value: 'da_email_entered',
      text: 'da_email_entered',
    },
    {
      key: 'addl_email_entered',
      value: 'addl_email_entered',
      text: 'addl_email_entered',
    },
    { key: 'telephone', value: 'telephone', text: 'telephone' },
    { key: 'fax', value: 'fax', text: 'fax' },
    { key: 'sex', value: 'sex', text: 'sex' },
    { key: 'voter_class', value: 'voter_class', text: 'voter_class' },
    { key: 'is_registered', value: 'is_registered', text: 'is_registered' },
    { key: 'status', value: 'status', text: 'status' },
    { key: 'is_completed', value: 'is_completed', text: 'is_completed' },
    {
      key: 'voting_address_street',
      value: 'voting_address_street',
      text: 'voting_address_street',
    },
    {
      key: 'voting_address_city',
      value: 'voting_address_city',
      text: 'voting_address_city',
    },
    {
      key: 'voting_address_state',
      value: 'voting_address_state',
      text: 'voting_address_state',
    },
    {
      key: 'voting_address_county',
      value: 'voting_address_county',
      text: 'voting_address_county',
    },
    {
      key: 'voting_address_zip',
      value: 'voting_address_zip',
      text: 'voting_address_zip',
    },
    {
      key: 'leo_jurisdiction',
      value: 'leo_jurisdiction',
      text: 'leo_jurisdiction',
    },
    { key: 'leo_id', value: 'leo_id', text: 'leo_id' },
    { key: 'leo_email', value: 'leo_email', text: 'leo_email' },
    {
      key: 'abroad_address1',
      value: 'abroad_address1',
      text: 'abroad_address1',
    },
    {
      key: 'abroad_address2',
      value: 'abroad_address2',
      text: 'abroad_address2',
    },
    {
      key: 'abroad_address3',
      value: 'abroad_address3',
      text: 'abroad_address3',
    },
    { key: 'abroad_city', value: 'abroad_city', text: 'abroad_city' },
    { key: 'abroad_state', value: 'abroad_state', text: 'abroad_state' },
    { key: 'abroad_zip', value: 'abroad_zip', text: 'abroad_zip' },
    { key: 'abroad_country', value: 'abroad_country', text: 'abroad_country' },
    {
      key: 'mailing_address1',
      value: 'mailing_address1',
      text: 'mailing_address1',
    },
    {
      key: 'mailing_address2',
      value: 'mailing_address2',
      text: 'mailing_address2',
    },
    {
      key: 'mailing_address3',
      value: 'mailing_address3',
      text: 'mailing_address3',
    },
    { key: 'mailing_city', value: 'mailing_city', text: 'mailing_city' },
    { key: 'mailing_state', value: 'mailing_state', text: 'mailing_state' },
    { key: 'mailing_zip', value: 'mailing_zip', text: 'mailing_zip' },
    {
      key: 'mailing_country',
      value: 'mailing_country',
      text: 'mailing_country',
    },
    {
      key: 'receive_ballot_by',
      value: 'receive_ballot_by',
      text: 'receive_ballot_by',
    },
    { key: 'date_of_birth', value: 'date_of_birth', text: 'date_of_birth' },
    { key: 'donotcall', value: 'donotcall', text: 'donotcall' },
    { key: 'donotcontact', value: 'donotcontact', text: 'donotcontact' },
    { key: 'emailoptin', value: 'emailoptin', text: 'emailoptin' },
    { key: 'mobileoptin', value: 'mobileoptin', text: 'mobileoptin' },
    { key: 'phoneoptin', value: 'phoneoptin', text: 'phoneoptin' },
    {
      key: 'reminder_opt_in',
      value: 'reminder_opt_in',
      text: 'reminder_opt_in',
    },
    {
      key: 'reminder_date_planned',
      value: 'reminder_date_planned',
      text: 'reminder_date_planned',
    },
    {
      key: 'reminder_date_sent',
      value: 'reminder_date_sent',
      text: 'reminder_date_sent',
    },
    {
      key: 'is_requesting_gpp_ballot',
      value: 'is_requesting_gpp_ballot',
      text: 'is_requesting_gpp_ballot',
    },
    {
      key: 'vfa_site_domain',
      value: 'vfa_site_domain',
      text: 'vfa_site_domain',
    },
    {
      key: 'vfa_site_language',
      value: 'vfa_site_language',
      text: 'vfa_site_language',
    },
    {
      key: 'cookiesnecessary',
      value: 'cookiesnecessary',
      text: 'cookiesnecessary',
    },
    {
      key: 'cookiespreference',
      value: 'cookiespreference',
      text: 'cookiespreference',
    },
    {
      key: 'cookiesstatistics',
      value: 'cookiesstatistics',
      text: 'cookiesstatistics',
    },
    {
      key: 'cookiesmarketing',
      value: 'cookiesmarketing',
      text: 'cookiesmarketing',
    },
    { key: 'created_by', value: 'created_by', text: 'created_by' },
    { key: 'id', value: 'id', text: 'id' },
    {
      key: 'error_rerun_created_by',
      value: 'error_rerun_created_by',
      text: 'error_rerun_created_by',
    },
    {
      key: 'error_rerun_notes',
      value: 'error_rerun_notes',
      text: 'error_rerun_notes',
    },
  ],
  anonymized: [
    { key: 'created_at', value: 'created_at', text: 'created_at' },
    { key: 'updated_at', value: 'updated_at', text: 'updated_at' },
    { key: 'country', value: 'country', text: 'country' },
    { key: 'state', value: 'state', text: 'state' },
    { key: 'party', value: 'party', text: 'party' },
    { key: 'join_da', value: 'join_da', text: 'join_da' },
    {
      key: 'nb_add_or_update',
      value: 'nb_add_or_update',
      text: 'nb_add_or_update',
    },
    { key: 'sex', value: 'sex', text: 'sex' },
    { key: 'voter_class', value: 'voter_class', text: 'voter_class' },
    { key: 'is_registered', value: 'is_registered', text: 'is_registered' },
    { key: 'status', value: 'status', text: 'status' },
    { key: 'is_completed', value: 'is_completed', text: 'is_completed' },
    {
      key: 'voting_address_state',
      value: 'voting_address_state',
      text: 'voting_address_state',
    },
    {
      key: 'voting_address_county',
      value: 'voting_address_county',
      text: 'voting_address_county',
    },
    {
      key: 'leo_jurisdiction',
      value: 'leo_jurisdiction',
      text: 'leo_jurisdiction',
    },
    { key: 'leo_id', value: 'leo_id', text: 'leo_id' },
    { key: 'abroad_country', value: 'abroad_country', text: 'abroad_country' },
    {
      key: 'receive_ballot_by',
      value: 'receive_ballot_by',
      text: 'receive_ballot_by',
    },
    { key: 'year_of_birth', value: 'year_of_birth', text: 'year_of_birth' },
    { key: 'donotcall', value: 'donotcall', text: 'donotcall' },
    { key: 'donotcontact', value: 'donotcontact', text: 'donotcontact' },
    { key: 'emailoptin', value: 'emailoptin', text: 'emailoptin' },
    { key: 'mobileoptin', value: 'mobileoptin', text: 'mobileoptin' },
    { key: 'phoneoptin', value: 'phoneoptin', text: 'phoneoptin' },
    {
      key: 'reminder_opt_in',
      value: 'reminder_opt_in',
      text: 'reminder_opt_in',
    },
    {
      key: 'reminder_date_planned',
      value: 'reminder_date_planned',
      text: 'reminder_date_planned',
    },
    {
      key: 'reminder_date_sent',
      value: 'reminder_date_sent',
      text: 'reminder_date_sent',
    },
    {
      key: 'is_requesting_gpp_ballot',
      value: 'is_requesting_gpp_ballot',
      text: 'is_requesting_gpp_ballot',
    },
    {
      key: 'vfa_site_domain',
      value: 'vfa_site_domain',
      text: 'vfa_site_domain',
    },
    {
      key: 'vfa_site_language',
      value: 'vfa_site_language',
      text: 'vfa_site_language',
    },
    { key: 'id', value: 'id', text: 'id' },
  ],
};

const defaultSelectedColumns = {
  full: [
    'created_at',
    'first_name',
    'middle_name',
    'last_name',
    'country',
    'nb_id',
    'email',
    'telephone',
    'voter_class',
    'is_registered',
    'status',
    'voting_address_street',
    'voting_address_city',
    'voting_address_state',
    'leo_jurisdiction',
    'leo_id',
    'leo_email',
    'receive_ballot_by',
    'date_of_birth',
    'reminder_date_planned',
  ],
  anonymized: [
    'created_at',
    'country',
    'join_da',
    'voter_class',
    'is_registered',
    'status',
    'voting_address_state',
    'leo_jurisdiction',
    'leo_id',
    'receive_ballot_by',
    'year_of_birth',
    'reminder_date_planned',
  ],
};

interface ColumnsSelectProps {
  targetQuery: string;
  setRadioValue: React.Dispatch<React.SetStateAction<string>>;
  radioValue: string;
  setColumnsValue: React.Dispatch<React.SetStateAction<string[]>>;
  columnsValue: string[];
}

const ColumnsSelect: FC<ColumnsSelectProps> = ({
  targetQuery,
  setRadioValue,
  radioValue,
  setColumnsValue,
  columnsValue,
}) => {
  //  const [radioValue, setRadioValue] = React.useState('ALL');
  //  const [columnsValue, setColumnsValue] = React.useState(defaultSelectedColumns[targetQuery]);
  useEffect(() => {
    if (!radioOptions.map((option) => option.value).includes(radioValue)) {
      setRadioValue('ALL');
      setColumnsValue(defaultSelectedColumns[targetQuery]);
    }
  }, [radioValue]);

  const handleCheckboxChange = (value: string) => {
    if (columnsValue.includes(value)) {
      setColumnsValue(columnsValue.filter((row) => row !== value));
    } else {
      setColumnsValue(columnsValue.concat([value]));
    }
  };

  return (
    <Segment>
      <h4>Column Selection</h4>
      <Form.Group>
        <List horizontal>
          {radioOptions.map((row) => (
            <ListItem>
              <Radio
                key={row.key}
                label={row.text}
                value={row.value}
                checked={radioValue === row.value}
                onChange={(e, data) => setRadioValue(data.value as string)}
              />
            </ListItem>
          ))}
        </List>
      </Form.Group>
      {radioValue === 'SELECTED' && (
        <List>
          {columnOptions[targetQuery].map((row) => (
            <ListItem>
              <Checkbox
                key={row.key}
                label={row.text}
                value={row.value}
                checked={columnsValue.includes(row.value)}
                onChange={(e, data) =>
                  handleCheckboxChange(data.value as string)
                }
              />
            </ListItem>
          ))}
        </List>
      )}
    </Segment>
  );
};

export default ColumnsSelect;
