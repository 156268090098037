import React, { FC, useEffect, useState } from 'react';

import { Dropdown, Segment, Form, SegmentProps } from 'semantic-ui-react';
import { leoOptionsDataObject } from '@/API';
import VotingStateSelect from './VotingStateSelect';

interface VotingStateAndJurisdictionSelectProps {
  leoOptionsData: leoOptionsDataObject[];
  setVotingStates: React.Dispatch<React.SetStateAction<string[]>>;
  votingStates: string[];
  setLeoJurisdictions: React.Dispatch<React.SetStateAction<string[]>>;
  leoJurisdictions: string[];
  props?: SegmentProps;
}

const VotingStateAndJurisdictionSelect: FC<VotingStateAndJurisdictionSelectProps> = ({
  leoOptionsData,
  setVotingStates,
  votingStates,
  setLeoJurisdictions,
  leoJurisdictions,
  props,
}) => {
  const calculateLeoOptions = (statesSelected: string[]) => {
    const validOptions = leoOptionsData
      ? leoOptionsData.filter(
          (option) =>
            statesSelected.length === 0 ||
            statesSelected.includes(option.state),
        )
      : [];
    return validOptions.map((option) => ({
      key: `${option.state}_${option.leoJurisdiction}`,
      value: `${option.state}_${option.leoJurisdiction}`,
      text: `${option.state}_${option.leoJurisdiction}`,
    }));
  };

  const [leoOptions, setLeoOptions] = useState(calculateLeoOptions([]));

  useEffect(() => {
    // When the LEO Options are loaded or when votingStates changes, including form reset:
    const newLeoOptions = calculateLeoOptions(votingStates);
    setLeoOptions(newLeoOptions);
    // And update the selected jurisdictions to only be in currently selected states
    const newLeos = leoJurisdictions.filter((leoJurisdiction) =>
      newLeoOptions.map((option) => option.value).includes(leoJurisdiction),
    );
    setLeoJurisdictions(newLeos);
  }, [votingStates, leoOptionsData]);

  return (
    <Segment basic vertical {...props}>
      <Form.Field>
        <label>Voting State</label>
        <VotingStateSelect
          search
          multiple
          value={votingStates}
          selection
          onChange={(_evt, data) => setVotingStates(data.value as string[])}
        />
      </Form.Field>

      <Form.Field>
        <label>LEO Jurisdiction</label>
        <Dropdown
          search
          multiple
          value={leoJurisdictions}
          selection
          options={leoOptions}
          onChange={(_evt, data) => setLeoJurisdictions(data.value as string[])}
        />
      </Form.Field>
    </Segment>
  );
};

export default VotingStateAndJurisdictionSelect;
